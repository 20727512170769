body {
    margin: 0;
  
    font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
    color: #333;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.66;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  body, h1, h2, h3, h4, h5 {
    font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
  
    /*font-family: "Poppins", sans-serif;*/
    color:#0D1B2A
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin: 10px 0;
  }
  
  html, body {
    font-size: 16px;
    line-height: 1.5;
  }
  
  h1 {
    font-size: 40px;
    font-weight: bolder;
    color: #444;
  }
  
  h2 {
    color: #444;
    font-size: 30px;
    font-weight: bolder;
  }
  
  
  main{
    background-color: #fff;
  }
  
  img{
    max-width: 100%;
  }
  
  /*
  #0D1B2A
  #1B263B
  #415A77
  #778DA9
  #E0E1DD
  */